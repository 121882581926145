<template>
  <v-list-item :to="link" class="appbar-menu-level1 menu-item mb-0" active-class="active">
    <v-list-item-icon class="menu-item-icon mr-3">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title class="menu-item-title mt-1">{{ label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    link: {
      Object
    },
    icon: {
      String,
      required: true
    },
    label: {
      String,
      required: true
    }
  }
}
</script>
