class LocalStore {
  _store = null

  constructor(localStorage) {
    this._store = localStorage
  }

  setStore(store) {
    this._store = store
  }

  hasFixedParams() {
    return this._store.getItem('fixed_params') !== null
  }

  getFixedParams() {
    return JSON.parse(this._store.getItem('fixed_params'))
  }

  setFixedParams(params) {
    this._store.setItem('fixed_params', JSON.stringify(params))
    return this
  }

  removeFixedParams() {
    this._store.removeItem('fixed_params')
    return this
  }

  getPcAccessToken() {
    return this._store.getItem('pc_access_token')
  }

  setPcAccessToken(token) {
    this._store.setItem('pc_access_token', token)
    return this
  }

  removePcAccessToken() {
    this._store.removeItem('pc_access_token')
    return this
  }

  getPcRefreshToken() {
    return this._store.getItem('pc_refresh_token')
  }

  setPcRefreshToken(token) {
    this._store.setItem('pc_refresh_token', token)
    return this
  }

  removePcRefreshToken() {
    this._store.removeItem('pc_refresh_token')
    return this
  }

  getPcExpiresAt() {
    return this._store.getItem('pc_expires_at')
  }

  setPcExpiresAt(expiresAt) {
    this._store.setItem('pc_expires_at', expiresAt)
    return this
  }

  removePcExpiresAt() {
    this._store.removeItem('pc_expires_at')
    return this
  }

  getDontShowApplySettingsNotice() {
    return this._store.getItem('dont_show_apply_settings_notice')
  }

  setDontShowApplySettingsNotice(value) {
    this._store.setItem('dont_show_apply_settings_notice', value)
    return this
  }

  removeDontShowApplySettingsNotice() {
    this._store.removeItem('dont_show_apply_settings_notice')
    return this
  }

  hasMatomoId() {
    return this._store.getItem('matomoid') !== null
  }

  setMatomoId(id) {
    this._store.setItem('matomoid', id)
    return this
  }

  getMatomoId() {
    return this._store.getItem('matomoid')
  }

  removeMatomoId() {
    this._store.removeItem('matomoid')
    return this
  }

  getPcTheme() {
    return this._store.getItem('pc_theme')
  }

  setPcTheme(theme) {
    this._store.setItem('pc_theme', theme)
    return this
  }

  clear() {
    this._store.clear()
    return this
  }

  logout() {
    this.removePcAccessToken()
      .removePcRefreshToken()
      .removePcExpiresAt()
  }
}

let store = new LocalStore(localStorage)

export default store
