<template>
  <v-list-item :to="link" class="appbar-menu-level2 sub-menu-item mb-0" active-class="active">
    <v-list-item-title>{{ label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'SubMenuItem',
  props: {
    link: {
      Object
    },
    label: {
      String,
      required: true
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#navdrawer
  .sub-menu-item
    padding: 6px 0 2px 44px
</style>
