import Vue from 'vue'
import App from './App.ui.vue'
import Router from 'vue-router'
import router from './router/router'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify.ui'
import format from './plugins/format.ui'

import config from './config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import urlParametersHelper from './util/UrlParametersHelper'
import VueMatomo from 'vue-matomo'
import cookiebot from './cookiebot'

import VueAB from './util/ABTestHelper'
import Logger from '@/util/Logger'
import LocalStore from '@/util/LocalStore'

if (!process || process.env.NODE_ENV !== 'test') {
  router.beforeEach((to, from, next) => {
    if (urlParametersHelper.hasFixedParameters(to)) {
      LocalStore.setFixedParams(urlParametersHelper.getFixedParameters(to))
    }

    next()
  })

  Vue.use(Router)
}

Vue.use(VueAB)

// disable production tip in console
Vue.config.productionTip = false

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Enable Cookiebot
if (process.env.VUE_APP_COOKIEBOT_ENABLED === 'true') {
  Logger.log('Loading Cookiebot')
  Vue.use(cookiebot)
}

// Enable Matomo Tracking
if (process.env.VUE_APP_MATOMO_ENABLED === 'true') {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_BASE_URL,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    trackerUrl: process.env.VUE_APP_MATOMO_BASE_URL + '/matomo.php',
    trackerScriptUrl: process.env.VUE_APP_MATOMO_BASE_URL + '/matomo.js',
    router: router,
    requireCookieConsent: true,
    enableLinkTracking: true,
    userId: LocalStore.getMatomoId()
  })

  // Add matomo-loaded class to body for e2e tests
  window.document.body.classList.add('matomo-loaded')
}

window.app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  format,
  config,
  render: h => h(App)
})
